import { Breadcrumb } from 'antd'
import { memo } from 'react'
import { useSelector } from 'react-redux'
// import { RouteComponentProps } from 'react-router'
import { StoreState } from 'src/types'
// import { AppProps } from 'src/ui/AppScheduler'
import { RouteItem } from './SideBar'
import { HomeOutlined } from '@ant-design/icons'
// type Props = RouteComponentProps & Pick<AppProps, 'dashboard'>
const Breadcrumbs = () => {
  const breadcrumb = useSelector((state: StoreState) => state.dashboard.breadcrumb)
  return (
    <div className="breadcrumb-container">
      <Breadcrumb>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>{breadcrumb.name}</Breadcrumb.Item>
        {breadcrumb.children.map((item: RouteItem) => (
          <Breadcrumb.Item key={item.id}>{item.name}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  )
}

export default memo(Breadcrumbs)
