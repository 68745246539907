import { message } from 'antd'
import axios, { AxiosError } from 'axios'
import { SYSTEM_ERROR } from 'src/helper/constant'
import { clearLoginStatus, getToken } from 'src/helper/user'
axios.defaults.baseURL = process.env.baseUrl
axios.interceptors.request.use(
  request => {
    const token = getToken()
    if (token) {
      request.headers['Authorization'] = 'Bearer ' + token
    }
    return request
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status !== 200) {
      message.warning(res.message || res.mesg || SYSTEM_ERROR, 5)
      if (res.code === 50008 || res.code === 50012 || res.code === 50014 || res.code == '9998') {
        clearLoginStatus()
        window.location.reload()
      }
      // to re-login
      // return Promise.reject(new Error(res.message || SYSTEM_ERROR))
    }
    if (res.code != '000000' && !res.access_token && res.code != '2000') {
      message.warning(res.mesg, 5)
      return Promise.reject(res)
    }
    // @ts-ignore
    if (response.config.login) return res
    return res.data
  },
  (error: AxiosError) => {
    //@ts-ignore
    switch (error.response?.status) {
      case 504:
        clearLoginStatus()
        window.location.reload()
        break
    }
    // @ts-ignore
    message.error(error.mesg || error.message)
    return Promise.reject(error)
  }
)
export default axios
