export const storage = {
  get(key: string): string {
    return window.localStorage[key]
  },
  set(key: string, value: any) {
    window.localStorage[key] = value
  },
  exists(key: string) {
    return window.localStorage[key] != null
  },
  getObject(key: string, def?: any) {
    let ret = def
    if (this.exists(key)) {
      const json = window.localStorage[key]
      try {
        ret = JSON.parse(json)
      } catch (error) {
        console.error(`Error parsing store object:${key}. Returning default: ${def}.[${error}]`)
      }
    }
    return ret
  },
  setObject(key: string, value: any) {
    let json
    try {
      json = JSON.stringify(value)
    } catch (error) {
      throw new Error(`Could not stringify object: ${key}.[${error}]`)
    }
    try {
      this.set(key, json)
    } catch (error) {
      throw new Error(`Could not save item in localStorage: ${key}. [${error}]`)
    }
    return true
  },
  delete(key: string) {
    window.localStorage.removeItem(key)
  },
}
