import { useRef } from 'react'
import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/types'
import { RouteItem } from './SideBar'
interface Props {
  show: boolean
  setHidden: Function
}
interface ChildrenProps {
  children: RouteItem[]
  level: number
}

function RenderChildren(props: ChildrenProps) {
  return (
    <>
      {props.children.map(item => {
        return (
          <div className="spread-menu--content" key={item.id}>
            {item.children.length ? (
              <h3 className={`level-${props.level}--title`}>{item.name}</h3>
            ) : (
              <a href={window.location.origin + item.href} target="_blank" rel="noreferrer">
                {item.name}
              </a>
            )}
            {item.children.length ? <RenderChildren children={item.children} level={3} /> : null}
          </div>
        )
      })}
    </>
  )
}
function renderMenus(menus: RouteItem[], ref: HTMLDivElement, show: boolean) {
  const JSXDom = (
    <div className="spread-menu--container">
      <div className="spread-menu--body">
        {menus.map(item => {
          return (
            <>
              <div className="spread-menu--category" key={item.id}>
                <h3 className="level-1--title">{item.name}</h3>
                <RenderChildren children={item.children} level={2} />
              </div>
            </>
          )
        })}
      </div>
    </div>
  )

  ReactDOM.render(JSXDom, ref)
}

export const SpreadMenus = (props: Props) => {
  const menuRef = useRef<HTMLDivElement>(document.createElement('div'))
  const menus = useSelector((state: StoreState) => state.dashboard.menus)
  const collapsed = useSelector((state: StoreState) => state.dashboard.collapsed)
  // 过滤动画开始时 触发mouseleave
  const transitionMouseleave = useRef<Boolean | null>(null)
  useEffect(() => {
    menuRef.current.className = 'spread-menu animate__animated animate__fadeInDown'
    props.show && document.body.appendChild(menuRef.current)
    menuRef.current.addEventListener('mouseleave', mouseleave)

    return () => {
      menuRef.current.removeEventListener('mouseleave', mouseleave)
    }
  }, [])
  const mouseleave = (e: Event) => {
    if (e.currentTarget) {
      if (transitionMouseleave.current) {
        props.setHidden()
        transitionMouseleave.current = false
      } else {
        transitionMouseleave.current = true
      }
    }
  }
  useEffect(() => {
    if (props.show) {
      if (collapsed) {
        menuRef.current.style.left = '40px'
      } else {
        menuRef.current.style.left = '200px'
      }
      document.body.appendChild(menuRef.current)
      renderMenus(menus, menuRef.current, props.show)
    } else {
      transitionMouseleave.current = false
      menuRef.current.parentNode?.removeChild(menuRef.current)
    }
  }, [props.show, menus, collapsed])
  return null
}
