import { PureComponent, createRef } from 'react'

export interface Props {
  onClick: () => void

  includeButtonPress: boolean
  parent: Window | Document
  useCapture?: boolean
}

interface State {}

export class ClickOutsideWrapper extends PureComponent<Props, State> {
  static defaultProps = {
    includeButtonPress: true,
    parent: window,
    useCapture: false,
  }
  myRef = createRef<HTMLDivElement>()

  componentDidMount() {
    this.props.parent.addEventListener('click', this.onOutsideClick, this.props.useCapture)
    if (this.props.includeButtonPress) {
      this.props.parent.addEventListener('keyup', this.onOutsideClick, this.props.useCapture)
    }
  }

  componentWillUnmount() {
    this.props.parent.removeEventListener('click', this.onOutsideClick, this.props.useCapture)
    if (this.props.includeButtonPress) {
      this.props.parent.removeEventListener('keyup', this.onOutsideClick, this.props.useCapture)
    }
  }

  onOutsideClick = (event: any) => {
    const domNode = this.myRef.current

    if (!domNode || !domNode.contains(event.target)) {
      this.props.onClick()
    }
  }

  render() {
    return <div ref={this.myRef}>{this.props.children}</div>
  }
}
