import { UrlQueryMap } from 'src/types'

/**
 * Parses an escaped url query string into key-value pairs.
 * Attribution: Code dervived from https://github.com/angular/angular.js/master/src/Angular.js#L1396
 * @returns {Object.<string,boolean|Array>}
 */
export function parseKeyValue(keyValue: string) {
  var obj: any = {}
  const parts = (keyValue || '').split('&')
  for (let keyValue of parts) {
    let splitPoint: number | undefined
    let key: string | undefined
    let val: string | undefined | boolean

    if (keyValue) {
      key = keyValue = keyValue.replace(/\+/g, '%20')
      splitPoint = keyValue.indexOf('=')

      if (splitPoint !== -1) {
        key = keyValue.substring(0, splitPoint)
        val = keyValue.substring(splitPoint + 1)
      }

      key = tryDecodeURIComponent(key)

      if (key !== undefined) {
        val = val !== undefined ? tryDecodeURIComponent(val as string) : true

        let parsedVal: any
        if (typeof val === 'string' && val !== '') {
          parsedVal = val === 'true' || val === 'false' ? val === 'true' : Number(val)
        } else {
          parsedVal = val
        }

        if (!obj.hasOwnProperty(key)) {
          obj[key] = isNaN(parsedVal) ? val : parsedVal
        } else if (Array.isArray(obj[key])) {
          obj[key].push(val)
        } else {
          obj[key] = [obj[key], isNaN(parsedVal) ? val : parsedVal]
        }
      }
    }
  }

  return obj
}

function tryDecodeURIComponent(value: string): string | undefined {
  try {
    return decodeURIComponent(value)
  } catch (e) {
    return undefined
  }
}

export function locationSearchToObject(search: string | number): UrlQueryMap {
  let queryString = typeof search == 'number' ? String(search) : search
  if (queryString.length > 0) {
    if (queryString.startsWith('?')) return parseKeyValue(queryString.substring(1))
    return parseKeyValue(queryString)
  }
  return {}
}
