import { AnyAction, combineReducers } from '@reduxjs/toolkit'
import dashboardReducers from 'src/core/features/dashbboard/state/reducers'

const rootReducers = {
  ...dashboardReducers,
}

const addedReducers = {}
export const addReducer = (newReducer: any) => {
  Object.assign(addedReducers, newReducer)
}

export const createRootReducer = () => {
  const appReducer = combineReducers({
    ...rootReducers,
    ...addedReducers,
  })
  return (state: any, action: AnyAction): any => {
    return appReducer(state, action)
  }
}
