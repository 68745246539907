import { useEffect, useState } from 'react'
import { initGlobalState, MicroAppStateActions, registerMicroApps, start } from 'qiankun'
import { clearLoginStatus, hasLogin } from 'src/helper/user'

import { addTab, clearTabsAndBreadcrumb, removeTab, setActiveRoute } from 'src/core/features/dashbboard/state'
import { RouteComponentProps } from 'react-router-dom'
import { AppProps } from 'src/ui/AppScheduler'
import { Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/types'
// 微应用 provider组件

export const MicroAppsProvider = (props: RouteComponentProps & AppProps) => {
  const [loading, setLoading] = useState(true)
  const activeRoute = useSelector((state: StoreState) => state.dashboard.activeRoute)
  const dispatch = useDispatch()
  useEffect(() => {
    let componentMounted = true
    if (!hasLogin() && props.location.pathname != '/print-page/agreement') {
      props.history.push('/login')
      return
    }

    // 注册应用
    registerMicroApps(
      window.app_cloud_runtime.apps.map(item => {
        return {
          ...item,
          container: '#micro-app-container',
          loader: loading => {
            if (componentMounted) {
              setLoading(loading)
            }
          },
        }
      })
    )
    // 启动
    if (!window.qiankunStarted) {
      window.qiankunStarted = true
      start({ prefetch: false })
    } else {
      start({ prefetch: false })
    }
    // 监听子应用事件
    const actions: MicroAppStateActions = initGlobalState({ logout: false, tab: null, removeTab: null })
    actions.onGlobalStateChange((state, prev) => {
      if (componentMounted) {
        // state: 变更后的状态; prev 变更前的状态
        if (state.logout) {
          // 清除登录状态
          clearLoginStatus()
          dispatch(clearTabsAndBreadcrumb())
          // this.props.history.push('/login')
          // 临时方案
          window.location.reload()
        } else if (state.tab) {
          dispatch(setActiveRoute(state.tab.href))
          dispatch(addTab(state.tab))
          props.history.push(state.tab.href)
          actions.setGlobalState({ tab: null })
        } else if (state.removeTab) {
          setTimeout(() => {
            dispatch(removeTab({ route: state.removeTab }))
            props.history.push(activeRoute)
            actions.setGlobalState({ removeTab: null })
          })
        }
      }
    })
    return () => {
      componentMounted = false
    }
  }, [])
  return (
    <Spin spinning={loading} tip="玩命加载中...">
      <div id="micro-app-container"></div>
    </Spin>
  )
}
