import { configureStore as reduxConfigureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createRootReducer } from 'src/core/reducers'
import { StoreState } from 'src/types'
import { setStore } from './store'
import { ThunkMiddleware } from 'redux-thunk'

export function configureStore() {
  const reduxDefaultMiddleware = getDefaultMiddleware<StoreState>({
    thunk: true,
    serializableCheck: false,
    immutableCheck: false,
  } as any)
  const store = reduxConfigureStore<StoreState>({
    reducer: createRootReducer(),
    middleware: [...reduxDefaultMiddleware] as [ThunkMiddleware<StoreState>],
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: {},
  })
  setStore(store)
  return store
}
