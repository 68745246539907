import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DashboardInitPhase, DashboardState } from 'src/types/dashboard'
import { DashboardModel } from './DashboardModel'
import { UserOrganization } from 'src/types'
import { storage } from 'src/helper/storage'
import { SIDEBAR_STATUS } from 'src/helper/constant'
import { RouteItem } from 'src/ui/components/Layout/SideBar'
const collapsed = storage.get(SIDEBAR_STATUS)
export const dashboardInitialState: DashboardState = {
  initPhase: DashboardInitPhase.NotStarted,
  permissions: [],
  microApp: {},
  tabs: [] as RouteItem[],
  getModel: () => {},
  userInfo: {} as UserOrganization,
  access_token: null,
  collapsed: collapsed ? (collapsed == 'false' ? false : true) : true,
  menus: [],
  breadcrumb: { children: [] } as RouteItem,
  activeRoute: '/old-version',
  openKeys: [],
  showNotice: false,
}
interface HistoryAndRemovRoute {
  route: RouteItem
}
const dashboardSlice = createSlice({
  name: 'dashborad',
  initialState: dashboardInitialState,
  reducers: {
    //  初始化请求
    dashboardInitFetching(state, PayloadAction) {
      state.initPhase = DashboardInitPhase.Fetching
    },
    // 初始化完成
    dashboardInitCompleted(state, action: PayloadAction<DashboardModel>) {
      state.getModel = () => action.payload
      state.initPhase = DashboardInitPhase.Completed
    },
    // 添加标签
    addTab(state, action: PayloadAction<RouteItem>) {
      const hasTab = state.tabs.find(item => item.href == action.payload.href)
      if (action.payload.children) {
        action.payload.children.forEach((item: RouteItem) => {
          delete item.parent
        })
      }
      if (!hasTab) state.tabs.push(action.payload)
    },
    // 删除标签
    removeTab(state, action: PayloadAction<HistoryAndRemovRoute>) {
      const index = state.tabs.findIndex(item => item.href == action.payload.route.href)
      if (~index) {
        state.activeRoute = state.tabs[index - 1].href
        state.tabs = state.tabs.filter(item => item.href != action.payload.route.href)
      }
    },
    // 添加菜单
    addMenus(state, action: PayloadAction<any>) {
      state.menus.push(action.payload)
    },
    // 设置菜单
    setMenus(state, action: PayloadAction<any>) {
      state.menus = action.payload
    },
    // 菜单折叠状态切换
    switchSideBarCollapsed(state, action: PayloadAction<any>) {
      state.collapsed = action.payload
    },
    // 添加面包屑
    addBreadcrumb(state, action: PayloadAction<RouteItem>) {
      state.breadcrumb = action.payload
    },
    // 设置当前路由
    setActiveRoute(state, action: PayloadAction<string>) {
      state.activeRoute = action.payload
    },
    // 清除面包屑和标签
    clearTabsAndBreadcrumb(state) {
      state.tabs = []
      state.breadcrumb = { children: [] } as RouteItem
    },
    // 设置通告
    showNotice(state, action: PayloadAction<boolean>) {
      state.showNotice = action.payload
    },
  },
})

export const dashboardReducer = dashboardSlice.reducer
export const {
  dashboardInitCompleted,
  dashboardInitFetching,
  addTab,
  switchSideBarCollapsed,
  addBreadcrumb,
  removeTab,
  setActiveRoute,
  clearTabsAndBreadcrumb,
  showNotice,
  setMenus,
} = dashboardSlice.actions
export default {
  dashboard: dashboardReducer,
}
