import { createFromIconfontCN } from '@ant-design/icons'

const IconFont = createFromIconfontCN({
  scriptUrl: window.app_cloud_runtime.iconfont,
})
export const IconsLoader = (type: string) => {
  // if (!type) {
  //   return React.createElement(HomeOutlined, { style: { fontSize: '16px' } })
  // }
  // @ts-ignore
  return <IconFont type={type || ' '} style={{ fontSize: '16px' }} />
}
