import { useCallback, useEffect, useMemo, useState } from 'react'
import { Menu, Skeleton } from 'antd'
import { checkTokenIsTimeout, getMenusByUserId } from 'src/api/user'
import { getUserId } from 'src/helper/user'
import { RouteComponentProps } from 'react-router-dom'
import { AppProps } from 'src/ui/AppScheduler'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/types'
import {
  addBreadcrumb,
  addTab,
  setActiveRoute,
  setMenus,
  switchSideBarCollapsed,
} from 'src/core/features/dashbboard/state'
import { storage } from 'src/helper/storage'
import { SIDEBAR_MENUS } from 'src/helper/constant'
import { IconsLoader } from '../Icon/IconsLoader'
import Sider from 'antd/lib/layout/Sider'

const { SubMenu } = Menu
// 后台响应报文
export interface RouteItem {
  id: string
  createdBy: string
  createdTime: Date
  updatedBy: string
  updatedTime: Date
  parentId: string
  name: string
  type: string
  href: string
  icon: string
  orderNum: number
  description: string
  children: any
  parent?: RouteItem
}
interface GeneraRoutes {
  routes: RouteItem[]
  current: RouteItem | null
}
function convertRoutesToJsonLinkedList(routes: RouteItem[], pathname: string): GeneraRoutes {
  let current: RouteItem | null = null
  function resovleChildren(children: RouteItem[], parent: RouteItem) {
    children.forEach((child: RouteItem) => {
      if (pathname.includes(child.href)) {
        current = child
      }
      child.parent = parent
      child.children.length && resovleChildren(child.children, child)
    })
  }
  for (let item of routes) {
    item.children.length && resovleChildren(item.children, item)
  }
  return { routes, current }
}
// 左侧边栏组件
export const SideBar = (props: RouteComponentProps & Pick<AppProps, 'dashboard'>) => {
  const [routes, setRoutes] = useState<RouteItem[]>([])
  const { history, location } = props
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const [selectedKey, setSelectedKey] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const collapsed = useSelector((state: StoreState) => state.dashboard.collapsed)
  const onMenuClick = useCallback(
    (parent: RouteItem | null, current: RouteItem) => {
      const p = { ...parent },
        c = { ...current }
      delete c.parent
      if (p) {
        delete p.children
        delete p.parent
        dispatch(
          addBreadcrumb({
            ...p,
            children: [c],
          } as any)
        )
      }

      dispatch(setActiveRoute(c.href))
      dispatch(addTab(c))
      history.push(c.href)
    },
    [history, dispatch]
  )
  // // 根据当前路由切换菜单
  useEffect(() => {
    setSelectedKey([location.pathname])
  }, [location.pathname])
  useEffect(() => {
    const userId = getUserId()

    let componentMounted = true
    if (userId) {
      getMenusByUserId<RouteItem[]>(userId).then((response: RouteItem[]) => {
        if (componentMounted) {
          // object is not extensible
          dispatch(setMenus(JSON.parse(JSON.stringify(response))))
          storage.setObject(SIDEBAR_MENUS, response)
          // 数据结构转换成JSON链表结构，方便面包屑、菜单等操作，不用每次查找
          const { routes, current } = convertRoutesToJsonLinkedList(response, location.pathname)
          setLoading(false)
          if (current) {
            const parent = { ...current.parent }
            // 匹配到路由=>展开状态
            dispatch(switchSideBarCollapsed(false))
            setOpenKeys([current.parent?.parent?.href!, current.href, current.parent?.href!])
            dispatch(setActiveRoute(current.href))
            setSelectedKey([current.href])
            // 避免栈溢出
            delete current.parent
            delete parent.parent
            try {
              dispatch(addTab(current))
              dispatch(
                addBreadcrumb({
                  ...parent,
                  children: [current],
                } as any)
              )
            } catch (e) {
              console.log(e)
            }
          } else {
            // 404页面由子应用处理
          }
          setRoutes(routes)
        }
      })
    }
    return () => {
      componentMounted = false
    }
  }, [])

  function renderSubMenus(route: RouteItem) {
    return (
      <SubMenu
        key={route.href}
        icon={IconsLoader(route.icon)}
        title={route.name}
        data-title={route.name}
        popupOffset={[16, 16]}
      >
        {route.children.map((item: RouteItem) => {
          return (
            <>
              {item.children.length ? (
                renderSubMenus(item)
              ) : (
                <Menu.Item
                  key={item.href}
                  icon={IconsLoader(item.icon)}
                  onClick={() => onMenuClick(route, item)}
                  data-title={item.name}
                  title={item.name}
                >
                  {item.name}
                </Menu.Item>
              )}
            </>
          )
        })}
      </SubMenu>
    )
  }
  const onOpenChange = (openKeys: any[]) => {
    // 每次菜单展开时 校验一次token
    checkTokenIsTimeout()
    setOpenKeys([...openKeys])
  }
  const menus = useMemo(() => {
    return (
      <div className="sider-menu-wrapper">
        <Skeleton loading={loading} active paragraph={{ rows: 14 }}>
          <Menu
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            selectedKeys={selectedKey}
            inlineIndent={10}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            collapsedWidth={40}
          >
            {routes.map(item => {
              return (
                <>
                  {item.children.length ? (
                    renderSubMenus(item)
                  ) : (
                    <Menu.Item
                      key={item.href}
                      icon={IconsLoader(item.icon)}
                      onClick={() => onMenuClick(null, item)}
                      data-title={item.name}
                      title={item.name}
                    >
                      {item.name}
                    </Menu.Item>
                  )}
                </>
              )
            })}
          </Menu>
        </Skeleton>
      </div>
    )
  }, [routes, collapsed, selectedKey, openKeys, loading])

  return (
    <Sider collapsed={collapsed} collapsedWidth={40} className="dashboard-sider" theme="light">
      {menus}
    </Sider>
  )
}
