import { SIDEBAR_STATUS, TOKEN_NAMESPACE, USER_ID, USER_NAME_STROAGE, CASHIER, SHOW_NOTICE } from './constant'
import { storage } from './storage'

export const getToken = (): string => storage.get(TOKEN_NAMESPACE)

export const setToken = (token: string) => storage.set(TOKEN_NAMESPACE, token)

export const removeToken = () => storage.delete(TOKEN_NAMESPACE)

export const hasLogin = (): string | boolean => getToken() && storage.get(USER_NAME_STROAGE)

export const getUserName = (): string => storage.get(USER_NAME_STROAGE)

export const getCashier = (): string => storage.get(CASHIER)
export const getUserId = (): string => storage.get(USER_ID)

export const setSideBarStatus = (status: boolean) => storage.set(SIDEBAR_STATUS, status)

export const clearLoginStatus = () => {
  removeToken()
  storage.delete(USER_NAME_STROAGE)
  storage.delete(USER_ID)
  storage.delete(SHOW_NOTICE)
}
