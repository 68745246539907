import { DashboardModel } from 'src/core/features/dashbboard/state'
import { RouteItem } from 'src/ui/components/Layout/SideBar'
import { UserOrganization } from './user'

export enum DashboardInitPhase {
  NotStarted = 'Not started',
  Fetching = 'Fetching',
  Services = 'Services',
  Failed = 'Failed',
  Completed = 'Completed',
}

export type GetMutableDashboardModelFn = () => DashboardModel | {} | any
// export interface BreadcrumbReducer {
//   breadcurmb: RouteItem
//   children: RouteItem[]
// }
export interface DashboardState {
  initPhase: DashboardInitPhase
  permissions: []
  getModel: GetMutableDashboardModelFn
  collapsed: boolean
  microApp: any
  tabs: RouteItem[]
  userInfo: UserOrganization
  access_token: null | string
  menus: RouteItem[]
  breadcrumb: RouteItem
  activeRoute: string
  openKeys: string[]
  showNotice: boolean
}

export interface NoticeItem {
  id: number
  timeType?: any
  ntTime: string
  dayType: string
  ntWeek: number
  ntDay?: any
  showType: string
  showTypeZh?: any
  ntModule: string
  ntModuleZh?: any
  ntStatus: string
  description: string
  ntCreator?: any
  ntCreatorName?: any
  showToday: string
  deleteAuto: string
  groupId: number
  showStartTime: Date
  showEndTime: Date
  createTime: string
  updateTime: string
  delFlag: number
}
