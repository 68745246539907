import { Dropdown, Menu } from 'antd'
import { memo, useCallback, useMemo, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { clearLoginStatus, getCashier } from 'src/helper/user'
import { AppProps } from 'src/ui/AppScheduler'
import { CloseOutlined, MenuOutlined, AppstoreOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { switchSideBarCollapsed } from 'src/core/features/dashbboard/state'
import { StoreState } from 'src/types'
import Breadcrumbs from './Breadcrumbs'
import classNames from 'classnames'
import { NoticeScrollComponent } from '../Notice/Notice'
import { SpreadMenus } from './SpreadMenus'
import { useState } from 'react'
import { ClickOutsideWrapper } from '../ClickOutside/ClickOutside'

type Props = RouteComponentProps & Pick<AppProps, 'dashboard'>

export const NavTop = memo((props: Props) => {
  const username = useUserName()
  const [show, setSpreadMenuShow] = useState(false)
  const collapsed = useSelector((state: StoreState) => state.dashboard.collapsed)
  const dispatch = useDispatch()
  const userHelpers = useUserHelpers(props)
  /* ------------------------------------兼容FireFox--------------------------------------------------------*/
  let isClick = useRef<boolean>(false)
  let spreadIsClick = useRef<boolean>(false)
  /* ------------------------------------菜单折叠--------------------------------------------------------*/
  const onMouseEnter = useCallback(() => {
    !isClick.current && collapsed && dispatch(switchSideBarCollapsed(false))
  }, [collapsed, dispatch])
  const onCollapsedHandle = useCallback(() => {
    isClick.current = true
    dispatch(switchSideBarCollapsed(!collapsed))
    setTimeout(() => {
      isClick.current = false
    }, 20)
  }, [collapsed, dispatch])
  /* ------------------------------------菜单快捷入口--------------------------------------------------------*/
  const onSpreadMenuMouseEnter = useCallback(() => {
    !spreadIsClick.current && !show && setSpreadMenuShow(true)
  }, [show])

  const onSpreadMenuClick = useCallback(() => {
    spreadIsClick.current = true
    setSpreadMenuShow(!show)
    setTimeout(() => {
      spreadIsClick.current = false
    }, 20)
  }, [show])
  const spreadMenuCls = classNames({
    'anticon-appstore--rotate': show,
  })
  const setHidden = () => {
    setSpreadMenuShow(false)
  }
  return (
    <>
      <div className="nav-container">
        <div className="nav-left">
          <div className="nav-left--close" onMouseEnter={onMouseEnter} onClick={onCollapsedHandle}>
            <span>{collapsed ? <MenuOutlined /> : <CloseOutlined />}</span>
          </div>
          <h3 className="nav-left--logo" onClick={() => props.history.push('/old-version')}>
            <span className="nav-left--title">V云管家</span>
          </h3>
        </div>
        <div className="nav-middle">
          <AppstoreOutlined
            onMouseEnter={onSpreadMenuMouseEnter}
            onClick={onSpreadMenuClick}
            className={spreadMenuCls}
            title="快捷入口"
          />
          <Breadcrumbs />
        </div>
        <div className="nav-right">
          <NoticeScrollComponent />
          <Dropdown overlay={userHelpers} placement="bottomRight" arrow>
            <span className="nav-right--username">{username}</span>
          </Dropdown>
        </div>
      </div>
      <ClickOutsideWrapper
        onClick={() => !spreadIsClick.current && setSpreadMenuShow(false)}
        includeButtonPress={false}
      >
        <SpreadMenus show={show} setHidden={setHidden} />
      </ClickOutsideWrapper>
    </>
  )
})
function useUserName() {
  return useMemo(() => {
    return getCashier()
  }, [])
}

function useUserHelpers(props: Props) {
  return useMemo(() => {
    function onLogoutHandle() {
      clearLoginStatus()
      window.location.reload()
    }
    function goEnterPrise() {
      props.history.push('/ship/enterprise/index')
    }
    return (
      <Menu>
        {localStorage.getItem('businessId') && <Menu.Item onClick={goEnterPrise}>企业中心</Menu.Item>}
        <Menu.Item onClick={onLogoutHandle}>退出</Menu.Item>
      </Menu>
    )
  }, [props.history])
}
