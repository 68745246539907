import React from 'react'
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { routes } from 'src/router/routes'
import { RouteDescriptor, StoreState } from 'src/types'
import AppRouteComponent from 'src/core/navigation/AppRouteComponent'
import { getToken, hasLogin } from 'src/helper/user'
import 'src/theme/layout/dashboard.less'
import { DashboardModel, initDashboard } from 'src/core/features/dashbboard/state'
import { connect } from 'react-redux'
import { SideBar } from './components/Layout/SideBar'
import { NavTop } from './components/Layout/NavTop'
import { MicroAppsProvider } from 'src/core/components/MicroAppsProvider/MicroAppsProvidr'
import { ConfigProvider, Layout, BackTop } from 'antd'
import { CaretUpOutlined } from '@ant-design/icons'
import { SentryErrorBoundary } from 'src/core/components/SentryErrorBoundary/SentryErrorBoundary'
import locale from 'antd/lib/locale/zh_CN'
import { NoticeModalComponent } from './components/Notice/Notice'
import { NavTabs } from './components/Layout/NavTabs'
const { Content } = Layout
export interface AppProps {
  initDashboard: typeof initDashboard
  dashboard: DashboardModel
  language: string | null
  collapsed: boolean
}
interface State {}
const backTopStyle = {
  height: 40,
  width: 40,
  lineHeight: '40px',
  borderRadius: 4,
  backgroundColor: '#1088e9',
  color: '#fff',
  textAlign: 'center',
  fontSize: 24,
}
export const mapStateToProps = (state: StoreState) => ({
  initPhase: state.dashboard.initPhase,
  language: state.language,
  // locale: (state.locale = state.language === 'zh_CN' ? zhCN : enUS),
  dashboard: state.dashboard.getModel() as any,
  collapsed: state.dashboard.collapsed,
})

// 负责主应用与子应用的调度渲染、访问权限控制、动态组件加载
class DashboardScheduler extends React.PureComponent<RouteComponentProps & AppProps, State> {
  componentDidMount() {
    this.props.initDashboard()
    document.getElementById('loading-container')!.style.display = 'none'
  }

  renderRoute(route: RouteDescriptor) {
    // const roles = route.roles ? route.roles() : []
    return (
      <Route
        exact
        path={route.path}
        key={route.path}
        render={props => {
          const hasToken = getToken()
          if (props.match.path === '/' && hasToken) {
            return <Redirect to={{ pathname: '/old-version', state: { from: props.location } }} />
          }

          const isLoginPage = route.path == '/login'
          if (isLoginPage && hasToken) {
            props.history.push('/old-version')
          }

          if (!isLoginPage && !hasToken) {
            props.history.push('/login')
          }
          return <AppRouteComponent {...props} {...this.props} route={route} />
        }}
      />
    )
  }
  renderComponet() {
    const { props } = this
    const pathname = props.location.pathname
    // 渲染AppRoute 组件
    if (pathname.includes('/login') && !hasLogin()) {
      const route = routes.find(item => item.path == pathname) as RouteDescriptor
      return <Switch>{<AppRouteComponent {...props} {...this.props} route={route} />}</Switch>
      // 渲染打印子应用
    } else if (pathname.startsWith('/print-page')) {
      return <MicroAppsProvider {...props} />
    }
    return (
      <div className="dashboard-layout">
        <SentryErrorBoundary>
          <Layout>
            <NavTop dashboard={props.dashboard} location={props.location} match={props.match} history={props.history} />
            <SideBar
              dashboard={props.dashboard}
              location={props.location}
              match={props.match}
              history={props.history}
            ></SideBar>
            <Content className={props.collapsed ? 'collapsed-side' : ''}>
              <NavTabs
                history={props.history}
                location={props.location}
                match={props.match}
                dashboard={props.dashboard}
              />
              <Switch>{routes.map(route => this.renderRoute(route))}</Switch>
              <MicroAppsProvider {...props} />
            </Content>
            {/* <Footer>Footer</Footer> */}
            <NoticeModalComponent />
          </Layout>
        </SentryErrorBoundary>
      </div>
    )
  }
  render() {
    return (
      <ConfigProvider componentSize="small" locale={locale}>
        {this.renderComponet()}
        <BackTop>
          {/* @ts-ignore */}
          <div style={backTopStyle} title="返回顶部">
            <CaretUpOutlined />
          </div>
        </BackTop>
      </ConfigProvider>
    )
  }
}
export default connect(mapStateToProps, { initDashboard })(withRouter(DashboardScheduler))
