import { SafeDynaimcImport } from 'src/core/components/DynamicImports/SafeDynamicImport'
import { RouteDescriptor } from 'src/types'
export const routes: RouteDescriptor[] = [
  {
    path: '/',
    component: SafeDynaimcImport(
      () => import(/* webpackChunkName: "DashboardPage" */ 'src/core/features/dashbboard/DahsobardPage')
    ),
    pageClass: 'page-dashboard',
  },
  {
    path: '/login',
    component: SafeDynaimcImport(() => import(/* webpackChunkName: "login" */ 'src/ui/components/Login/Login')),
    pageClass: 'page-login',
  },
  {
    path: '/icon-settings',
    component: SafeDynaimcImport(
      () => import(/* webpackChunkName: "icon-settings" */ 'src/ui/components/Icon/IconSettings')
    ),
    pageClass: 'icon-settings',
  },
]
