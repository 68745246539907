import { Tabs } from 'antd'
import { memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { removeTab, setActiveRoute } from 'src/core/features/dashbboard/state'
import { StoreState } from 'src/types'
import { AppProps } from 'src/ui/AppScheduler'
import { RouteItem } from './SideBar'
import classnames from 'classnames'
const { TabPane } = Tabs
type Props = RouteComponentProps & Pick<AppProps, 'dashboard'>
export const NavTabs = memo((props: Props) => {
  const { tabs, collapsed, activeRoute } = useSelector((state: StoreState) => {
    return {
      tabs: state.dashboard.tabs,
      collapsed: state.dashboard.collapsed,
      activeRoute: state.dashboard.activeRoute,
    }
  })
  const dispatch = useDispatch()
  const onTabChange = useCallback(
    (activeKey: string) => {
      props.history.push(activeKey)
    },
    [props.history]
  )
  useEffect(() => {
    dispatch(setActiveRoute(props.location.pathname + props.location.search))
  }, [props.location.pathname])
  const onTabEdit = (activeKey: any) => {
    const result = tabs.find((item: RouteItem) => item.href == activeKey)
    if (result) {
      dispatch(removeTab({ route: result }))
      setTimeout(() => {
        props.history.push(activeRoute)
      }, 100)
    }
  }
  const cls = classnames({
    'navtabs-container': true,
    'navtabs-container--collapsed': collapsed,
  })
  return (
    <div className={cls}>
      <Tabs type="editable-card" size="small" hideAdd activeKey={activeRoute} onChange={onTabChange} onEdit={onTabEdit}>
        {tabs.map((item: any) => (
          <TabPane tab={item.name || item.title} key={item.href} closable={item.closable}></TabPane>
        ))}
      </Tabs>
    </div>
  )
})
