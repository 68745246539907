// import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux'
import { ThunkResult } from 'src/types'
import { DashboardModel, dashboardInitFetching, dashboardInitCompleted, addTab } from '.'
export interface InitDashbaordArgs {
  grant_type: string
  scope: string
}

// export const loginToSystem: Middleware<{}, StoreState> = (store: MiddlewareAPI<Dispatch, StoreState>) => (
//   next: Dispatch
// ) => (action: AnyAction) => {
//   return next(action)
// }
// dashboard现在用的是老项目的

export function initDashboard(args?: InitDashbaordArgs): ThunkResult<any> {
  return async dispatch => {
    dispatch(dashboardInitFetching)
    const dashboard = new DashboardModel()
    dispatch(
      addTab({
        name: 'Dashboard',
        id: -1,
        closable: false,
        href: '/old-version/dashboard',
      } as any)
    )
    dispatch(dashboardInitCompleted(dashboard))
  }
}
