import { Component } from 'react'
import { withScope, captureException } from '@sentry/browser'
import { getUserName } from 'src/helper/user'
export class SentryErrorBoundary extends Component<any, any> {
  componentDidCatch(error: any, errorInfo: any): void {
    withScope(scope => {
      scope.setExtras(errorInfo)
      captureException(error)
      scope.setUser({
        user_name: getUserName(),
      })
    })
  }
  static getDerivedStateFromError(error: any) {
    console.log('getDerivedStateFromError>>>', error)
  }
  render() {
    return this.props.children
  }
}
