import ReactDOM from 'react-dom'
import './theme/layout/common.less'
import App from './App'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'

if (process.env.NODE_ENV == 'production') {
//   Sentry.init({
//     dsn: 'https://29797b84787d4c429d5619f7e199878d@sentry.shrwet.com/1',
//     integrations: [new Integrations.BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//     ignoreErrors: ['ResizeObserver loop limit exceeded'],
//     release: process.env.NODE_ENV,
//     allowUrls: ['https://cloud.shrwet.com,', 'https://test.shrwet.com'],
//   })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
