import axios from './index'
import { LoginParams } from '../types/user'
//Promise<AxiosResponse<LoginResponse>>
export function loginApi<T>(params: LoginParams) {
  return axios.post<T, T>(
    `/authorization-server/oauth/token?username=${params.username}&password=${params.password}&grant_type=password&scope=read`,
    params,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset UTF-8',
        Authorization: 'Basic dGVzdF9jbGllbnQ6dGVzdF9zZWNyZXQ=',
      },
      // @ts-ignore
      login: true,
    }
  )
}

export function getOrganizationByUsername<T>(params: object) {
  return axios.get<T, T>(`/organization/user`, { params })
}

export function getMenusByUserId<T>(userId: string) {
  return axios.get<T, T>(`/organization/menu/getmymenu/${userId}`)
}

export function getNoticeContent<T>() {
  return axios.post<T, T>('/kaola-storage/label/notice/backProgress', {})
}
export function checkTokenIsTimeout<T>() {
  return axios.post<T, T>('/rw-cloud-server/authEvent')
}
