export const TOKEN_NAMESPACE = 'Admin-Token'
export const SYSTEM_ERROR = 'System Error'
export const USER_NAME_STROAGE = 'username'
export const USER_ID = 'userId'
export const SIDEBAR_STATUS = 'sierbar_collapsed'
export const SIDEBAR_MENUS = 'sidebar_menus'
export const ROLEIDS = 'roleIds'
export const CASHIER = 'cashier'
export const SHOW_NOTICE = 'SHOW_NOTICE'
export const BUSINESSID = 'businessId'
