import { Modal } from 'antd'
import { useEffect, useState } from 'react'
import { SHOW_NOTICE } from 'src/helper/constant'
import { storage } from 'src/helper/storage'
import { NoticeItem, StoreState } from 'src/types'
import { CloseOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
export const NoticeModalComponent = () => {
  const showNotice = useSelector((store: StoreState) => store.dashboard.showNotice)
  useEffect(() => {
    if (showNotice) {
      let result: NoticeItem[] = storage.getObject(SHOW_NOTICE) || []
      result = result.filter(item => {
        if (item.showType.includes('NOTIFY_BAR') && item.ntModule.includes('V_ADMIN')) return item
      })
      if (result.length) {
        Modal.info({
          title: '公告',
          width: 680,
          content: (
            <div style={{ margin: '16px 0' }}>
              {result.map(item => {
                return <p>{item.description}</p>
              })}
            </div>
          ),
          onOk() {
            storage.delete(SHOW_NOTICE)
          },
        })
      }
    }
  }, [showNotice])
  return <></>
}

export const NoticeScrollComponent = () => {
  const [list, setState] = useState<NoticeItem[]>([])
  const showNotice = useSelector((store: StoreState) => store.dashboard.showNotice)
  useEffect(() => {
    if (showNotice) {
      let result: NoticeItem[] = storage.getObject(SHOW_NOTICE) || []
      result = result.filter((item: NoticeItem) => {
        if (item.showType.includes('SCROLL_BAR') && item.ntModule.includes('V_ADMIN')) return item
      })
      if (result.length) {
        setState(result)
      }
    }
  }, [showNotice])

  return (
    <>
      {list.length ? (
        <ul className="notice-list">
          <li className="notice-list--item">{list.map(item => item.description).join(' / ')}</li>
          <li className="notice-list--close" onClick={() => setState([])}>
            <CloseOutlined />
          </li>
        </ul>
      ) : null}
    </>
  )
}
