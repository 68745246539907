import { FC } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { configureStore } from './store'
import './App.css'
import DashboardScheduler from './ui/AppScheduler'
const App: FC = () => {
  return (
    <Provider store={configureStore()}>
      <BrowserRouter>
        <DashboardScheduler />
      </BrowserRouter>
    </Provider>
  )
}
export default App
